/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:73df71b8-49f7-414e-93bd-1511af703615",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_obEsAIoK1",
    "aws_user_pools_web_client_id": "491q0mmdjp7fdsjvto8gfb5kq9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://a2rpegqiwfaoxe7fgvvlowon6a.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-o5ynas2rezhcfceto4267ycfwm",
    "aws_user_files_s3_bucket": "redesignedoctomeme70ec66c221924790ab143e23bfcdf153950-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
